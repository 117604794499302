import React from 'react'
import Preloader from 'components/Preloader'

export default function PreloaderDemo() {
	return (
		<div className={'relative mx-auto flex w-1/2 justify-center'}>
			<Preloader side={250} />
		</div>
	)
}
